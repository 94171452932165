@import "@matillion/component-library/dist/assets/styles/colours";

.Node {
  background: $cloudy;
  padding: 16px;
  color: $storm-blue;
  border-left: 4px solid $electric-green;
  border-bottom: 1px dotted grey;
  border-top: 1px dotted grey;
  border-right: 1px dotted grey;
  border-radius: 2px;
}
