.TabContents {
    height: calc(100% - 28px);
}

.TabList {
    height: 28px;
}

.Tabs {
    height: 100%;
}

.CodeMirror {
    height: 100% !important;
}